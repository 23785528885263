<script>
import {decript} from './utils/decript'
const axios = require('axios');
var moment = require('moment')
export default {
	data() {
		return {
			start: false,
			email: this.$route.query.email,
			success: false,
			loading: false,
			startTime: null,
			endTime: null,
			questions3: [
			
				{
					id: 1,
					name: 'tn_name',
					label: 'Escribe tu nombre completo ',
					type: 'text',
					value: ''
				},
				{
					id: 2,
					name: 'tn_name2',
					label: 'Escribe tu nombre completo ',
					type: 'text',
					value: ''
				},
				
				

			],
			questions: [
			
				{
					id: 1,
					name: 'tn_name',
					label: 'Escribe tu nombre completo ',
					type: 'text',
					value: ''
				},
				
				{
					id: 2,
					name: 'tn_2',
					label: '¿Cuáles son los lenguajes y frameworks que utilizas actualmente? ',
					type: 'textarea',
					value: ''
				},
				{
					id: 3,
					name: 'tn_3',
					label: '¿Qué proyecto que hayas desarrollado ha sido el más desafiante para ti?',
					type: 'textarea',
					value: ''
				},
				{
					id: 4,
					name: 'tn_4',
					label: '¿Cómo te describirías a ti mismo como programador?',
					type: 'textarea',
					value: ''
				},
				{
					id: 5,
					name: 'tn_5',
					label: '¿Eres más orientado a objetivos o más preocupado por la calidad del código?',
					type: 'textarea',
					value: ''
				},
				{
					id: 6,
					name: 'tn_6',
					label: '¿Qué áreas de la programación consideras que se te dan mejor?',
					type: 'textarea',
					value: ''
				},
				{
					id: 7,
					name: 'tn_7',
					label: '¿Qué áreas de la programación te gustaría mejorar?',
					type: 'textarea',
					value: ''
				},
				{
					id: 8,
					name: 'tn_8',
					label: '¿Qué tipo de proyectos te gustaría trabajar en el futuro?',
					type: 'textarea',
					value: ''
				},
				{
					id: 9,
					name: 'tn_9',
					label: 'Ante nuevos retos ¿cuál es tu reacción?',
					type: 'textarea',
					value: ''
				},
				{
					id: 10,
					name: 'tn_10',
					label: '¿Cómo manejas la presión en el trabajo?',
					type: 'textarea',
					value: ''
				},
				{
					id: 11,
					name: 'tn_11',
					label: '¿Eres más un programador individual o un programador de equipo?',
					type: 'textarea',
					value: ''
				},
				{
					id: 12,
					name: 'tn_12',
					label: '¿Cuáles son tus expectativas de crecimiento en una empresa a largo plazo?',
					type: 'textarea',
					value: ''
				},
				{
					id: 13,
					name: 'tn_13',
					label: '¿Cuál es su expectativa salarial para este puesto (en Pesos Mexicanos)?',
					type: 'text',
					value: ''
				},
				{
					id: 14,
					name: 'tn_14',
					label: '¿Cómo funciona el ciclo de vida de un componente en Vue?',
					type: 'textarea',
					value: ''
				},
				{
					id: 15,
					name: 'tn_15',
					label: '¿Qué son los Mixins en Vue y para qué se utilizan?',
					type: 'textarea',
					value: ''
				},
				{
					id: 16,
					name: 'tn_16',
					label: '¿Qué son  las directivas en Vue y para qué se utilizan?',
					type: 'textarea',
					value: ''
				},
				{
					id: 17,
					name: 'tn_17',
					label: '¿Qué biblioteca se utiliza para el enrutamiento en una aplicación Vue?',
					type: 'textarea',
					value: ''
				},
				{
					id: 18,
					name: 'tn_18',
					label: '¿Cuál es la diferencia entre el enrutamiento del lado del servidor y el enrutamiento del lado del cliente?',
					type: 'textarea',
					value: ''
				},
				{
					id: 19,
					name: 'tn_19',
					label: '¿Cómo se utiliza la propiedad "router-link" para crear enlaces en una aplicación Vue?',
					type: 'textarea',
					value: ''
				},
				{
					id: 20,
					name: 'tn_20',
					label: '¿Cuál es la diferencia entre la propiedad "data" y la propiedad "computed" en Vue?',
					type: 'textarea',
					value: ''
				},
				{
					id: 21,
					name: 'tn_21',
					label: '¿Qué es Vuex y para qué se utiliza?',
					type: 'textarea',
					value: ''
				},
				{
					id: 22,
					name: 'tn_22',
					label: '¿Cuándo es apropiado utilizar una biblioteca de administración de estado de terceros, como Vuex, en comparación con el uso de la propiedad "data" en Vue?',
					type: 'textarea',
					value: ''
				},
				{
					id: 23,
					name: 'tn_23',
					label: '¿Qué son los controladores en Laravel y cómo se utilizan?',
					type: 'textarea',
					value: ''
				},
				{
					id: 24,
					name: 'tn_24',
					label: 'En Laravel, ¿cuál es la forma recomendada de gestionar la creación, actualización y eliminación de tablas y campos en la base de datos de manera eficiente y consistente?',
					type: 'textarea',
					value: ''
				},
				{
					id: 25,
					name: 'tn_25',
					label: '¿Qué es y para qué sirve una relación Polimórfica?',
					type: 'textarea',
					value: ''
				},
				{
					id: 26,
					name: 'tn_26',
					label: 'Supongamos que tenemos el modelo Usuarios en Laravel, con los atributos "nombre", "apellido" y "email". ¿Qué opción ofrece Laravel para crear un atributo virtual llamado "nombre_completo", que devuelva el valor concatenado de los atributos "nombre" y "apellido"',
					type: 'textarea',
					value: ''
				},
				{
					id: 27,
					name: 'tn_27',
					label: '¿Qué es Eloquent ORM de Laravel?',
					type: 'textarea',
					value: ''
				},
				{
					id: 28,
					name: 'tn_28',
					label: '¿Cómo protegerías la integridad de datos con Laravel?',
					type: 'textarea',
					value: ''
				},
				{
					id: 29,
					name: 'tn_29',
					label: '¿Cómo podemos aplicar el mismo prefijo y middleware a varias rutas en Laravel de forma eficiente y consistente?',
					type: 'textarea',
					value: ''
				},
				{
					id: 30,
					name: 'tn_30',
					label: '¿Qué es un token JWT y cómo se utiliza en Laravel para la autenticación?',
					type: 'textarea',
					value: ''
				},
				{
					id: 31,
					name: 'tn_31',
					label: '¿Qué es "passport" en Laravel y cómo se utiliza?',
					type: 'textarea',
					value: ''
				},
				{
					id: 32,
					name: 'tn_32',
					label: '¿Qué opciones ofrece Laravel para ejecutar tareas recurrentes en segundo plano?',
					type: 'textarea',
					value: ''
				},
				{
					id: 33,
					name: 'tn_33',
					label: '¿Qué es "Supervisor"?',
					type: 'textarea',
					value: ''
				},
				{
					id: 34,
					name: 'tn_34',
					label: 'En el lenguaje que prefieras, resuelve el siguente algoritmo y comparte tu codigo: Encontrar la subsecuencia más larga: Escribe una función que tome una matriz de números y encuentre la subsecuencia más larga de números consecutivos. Por ejemplo, si la matriz es [1, 2, 3, 4, 1, 2, 3, 4, 5], la subsecuencia más larga es [1, 2, 3, 4, 5].',
					type: 'textarea',
					value: ''
				},
				{
					id: 35,
					name: 'tn_35',
					label: `<p>En el lenguaje que prefieras, resuelve el siguente algoritmo y comparte tu codigo:</p> 
					<p>El Árbol Utopian pasa por 2 ciclos de crecimiento cada año. Cada primavera, su altura se duplica. Cada verano, su altura aumenta en 1 metro.</p>
					<p>Un esqueje del Árbol Utopian con una altura de 1 metro se planta al inicio de la primavera. ¿Qué altura tendrá el árbol después de n ciclos de crecimiento?</p>
					`,
					type: 'textarea',
					value: ''
				},

			],
			lastChangeTab: 0,
			changesTab: [],
			lastChangeWindow: 0,
			changesWindow: [],
		}  
	},
	methods: {
		send() {
			this.loading = true
			this.endTime = moment()
			axios.post('https://filter-form.sorianoariza.com/api/form-data', {
				email: this.email,
				name: this.questions[0].value,
				data: {
					start: this.startTime,
					end: this.endTime,
					changesTab: this.changesTab,
					changesWindow: this.changesWindow,
					questions: this.questions
				}
			}, {
				headers: {
					'Authorization': 'R2CkBZvM83MjQYSholIT'
				}
			}).then(res => {
				this.loading = false
				swal({
					title: "Registrado",
					text: "Muchas gracias por completar la evaluacion, te contactaremos en breve",
					type: "success",
					confirmButtonText: "Ok"
				});

				this.success = true
			})
		},
		detectedVisibility() {
			let vm = this
			document.addEventListener("visibilitychange", this.statusVisibility);
			window.addEventListener("blur", function() {
				// El usuario cambió a otra pestaña
				let currentStep = Number($(".step-active").attr("data-step-id"))
				let question = vm.questions.find((q, index) => index == currentStep -1)
				let id = Date.now()
				vm.lastChangeWindow = id
				vm.changesWindow.push({
					id: id,
					exitTime: moment(),
					question: question,
				})
			});
			window.addEventListener("focus", function() {
				// El usuario volvió a esta pestaña
				let change = vm.changesTab.find(c => c.id == vm.lastChangeWindow)
				if(change) {
					change.returnTime = moment();
				}
			});
		},

		statusVisibility() {
			if (document.hidden) {
				// El usuario cambió a otra pestaña
				let currentStep = Number($(".step-active").attr("data-step-id"))
				let question = this.questions.find((q, index) => index == currentStep -1)
				let id = Date.now()
				this.lastChangeTab = id
				this.changesTab.push({
					id: id,
					exitTime: moment(),
					question: question,
				})
			} else {
				// El usuario volvió a esta pestaña
				let change = this.changesTab.find(c => c.id == this.lastChangeTab)
				if(change) {
					change.returnTime = moment();
				}
			}
		}
	},
	mounted() {
		 
		let token = this.$route.query.token
		let email = null

		try {
			email = decript(token)
		} catch (error) {
			email = null
		}
		this.email = email
		let vm = this
		vm.detectedVisibility()
		if(this.email) {
			swal({
				title: "Atención",
				text: "Al iniciar la evaluación, se monitorea el cambio de pestaña del navegador. Por favor, intenta responder las preguntas en base a tu conocimiento. Valoramos mucho tu honestidad y compromiso durante la evaluación.",
				type: "warning",
				showCancelButton: true,
				cancelButtonText: "En otro momento",
				confirmButtonText: "Iniciar ahora"
			}, function (isConfirm) {
				if(isConfirm) {
					vm.start = true
					vm.startTime = moment()
				} else {
					window.location.href = "https://sorianoariza.com/"
				}
			})
		}
	}
}
</script>

<template>
  <div id="app">
    
    <div class="tuna-loader-container" v-if="loading">
        <div class="tuna-loader"></div>
    </div>
    <div class='tuna-signup-container tuna-green'>
        <div class='container'>
            <div class="row" >
                <div class='tuna-signup-left col-md-4 col-sm-4'>
                    <img class='tuna-signup-logo' src='images/logo.png' alt="logo"/>
                    <p>Cuestionario para candidatos a la vacante disponible</p>
                    <p class="tuna-trial">Recuerda ser breve y objetivo en tus respuestas</p>
                    <hr/>
                    <div class="tuna-slider-container">
                        <ul class="tuna-slider">
                            <li class='tuna-signup-testimonial'>
                                <i class="icon icon-quill"></i>
                                <p>Seguimos el método, pero lo hacemos distinto; es decir, INNOVAMOS.</p>
                                <b>Soriano Ariza & Asociados</b>
                            </li>
                          
                        </ul>
                    </div>
                </div>
                <div class='tuna-signup-right col-md-8 col-sm-8 ' v-if="email && !success">
					<div class='steps-count'>
						Pregunta <span class="step-current">1</span>/<span class="step-count"></span>
					</div>

					<div class="tuna-steps">
						<div v-for="(q, index) in questions" :key="`q-${index}`" class="step " :class="{'step-active': index == 0}"  :data-step-id='index + 1'>
							<label class="formLabel" :for="q.name" v-html="q.label"></label>
							<textarea v-if="q.type ==  'textarea'"  class="formInput" :id='q.name' :name="q.name" autocomplete="off" spellcheck="false" v-model="q.value" rows="1"></textarea>
							<input v-else :type="q.type" class="formInput" :id='q.name' :name="q.name" autocomplete="off" spellcheck="false" v-model="q.value" />
							<div class='help-error'><i class="icon icon-warning"></i> ¡El campo es obligatorio!</div>
						</div> 
					</div>
					<div class='button-container'>
						<a href="javascript:void(0)" class='btn btn-white-transparent btn-rounded prevStep' tabindex="1">← Anterior</a>
						<div>
							<a href="javascript:void(0)" class="btn btn-white btn-rounded nextStep" tabindex="0">Siguiente pregunta →</a>
						</div>
						<div>
							<button href="javascript:void(0)" class="btn btn-white btn-rounded sendForm" tabindex="0" @click="send">Enviar respuestas</button>
						</div>
					</div>
                </div>
				<div v-if="success"  class='tuna-signup-right col-md-8 col-sm-8 '>
					<div  class="text-center px-3">
						<h1>Gracias</h1>
						<h2>Estamos analizando tus respuestas y nos pondremos en contacto contigo en un plazo de 2 a 3 días hábiles</h2>
						<h2>Ya puedes cerrar esta ventana</h2>
					</div>
				</div>
				<div v-else-if="!email"  class='tuna-signup-right col-md-8 col-sm-8 '>
					<div class="text-center px-3">
						<h1>¡Ups! Sesión inválida</h1>
						<h2>Consulta a <a style="color: #fff" href="mailto:tecnologia@soriano-ariza.com">tecnologia@soriano-ariza.com</a></h2>
					</div>
				</div>
				
            </div>
        </div>
    </div>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
