import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {decript, encrypt} from './utils/decript'
Vue.config.productionTip = false
window.sorianoMailLink = function (email) {
  return `https://evaluacion.sorianoariza.com/?token=${encrypt(email)}`
}



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
